import ReactDOM from 'react-dom';
import * as ReactDOMClient from 'react-dom/client';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import App from 'App';
import { BASE_PATH, ENV } from 'config';
import store from 'store';
import * as serviceWorker from 'serviceWorker';
import reportWebVitals from 'reportWebVitals';
import { ConfigProvider } from 'contexts/ConfigContext';
import { saveState } from './store/persistState';

// style + assets
import 'assets/scss/style.scss';

import i18n from './i18n';

// Load persisted data at start up
store.subscribe(() => {
    saveState({ state: store.getState() });
});

// ==============================|| REACT DOM RENDER  ||============================== //

const root = ReactDOMClient.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <ConfigProvider>
            <BrowserRouter basename={BASE_PATH}>
                <App />
            </BrowserRouter>
        </ConfigProvider>
    </Provider>
);

// Set the document title based on the environment. Default to no environment name in the title
let title = 'Fixe Portal';
if (ENV === 'staging') {
    title = `${title} - Staging`;
}
if (ENV === 'development') {
    title = `${title} - Local`;
    console.log("Cognito Pool ID index.js :", process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID);
    console.log("App Client ID index.js :", process.env.REACT_APP_AWS_APP_CLIENT_ID);
}
document.title = title;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
